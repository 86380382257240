require('./bootstrap');

import "clocklet/css/clocklet.min.css";

import * as bootstrap from 'bootstrap';
import MCDatepicker from 'mc-datepicker';
import clocklet from "clocklet";

// Assign bootstrap to window variable
window.bootstrap = bootstrap;
// Assign bootstrap to window variable
window.MCDatepicker = MCDatepicker;
window.clocklet = clocklet;
